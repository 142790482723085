<template>
  <div
    :class="[
      scrollButton === true ? 'block' : 'hidden',
      'fixed right-4 bottom-4 z-10',
    ]"
  >
    <UButton
      icon="i-lucide-arrow-up"
      size="xl"
      class="rounded-full"
      @click="scrollTop"
    />
  </div>
</template>

<script setup lang="ts">
const scrollButton = ref(false);

const scrollTop = () => {
  window.scrollTo(0, 0);
};

const showOnScroll = () => {
  const y = window.scrollY;
  scrollButton.value = y >= 800;
};

onMounted(() => {
  window.addEventListener('scroll', showOnScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', showOnScroll);
});
</script>
